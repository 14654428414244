import React from "react";
import { useQuery } from "@tanstack/react-query";
import { useApi } from "../useApi";
import { Box, Title, Text, Flex } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { MiniGame } from "../dto/user";
import MiniGameCard from "../Components/MiniGameCard";

const MiniGames: React.FC = () => {
  const api = useApi();
  const navigate = useNavigate();

  const {
    data: miniGames,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["miniGames"],
    queryFn: () => api.get("/mini-games").then((res) => res.data),
  });

  const handlePlayMiniGame = (miniGameId: number) => {
    console.log(`Démarrage du mini-jeu avec l'id : ${miniGameId}`);
    navigate(`/mini-game/${miniGameId}`);
  };

  if (isLoading) return <Text>Chargement des mini-jeux...</Text>;
  if (error)
    return <Text color="red">Erreur lors du chargement des mini-jeux.</Text>;

  return (
    <Box p={16}>
      <Title order={1} mb="lg">
        Mini-Jeux
      </Title>

      <Flex wrap="wrap" gap="md">
        {miniGames.map((game: MiniGame) => (
          <MiniGameCard
            key={game.id}
            miniGame={game}
            onPlay={handlePlayMiniGame}
          />
        ))}
      </Flex>
    </Box>
  );
};

export default MiniGames;
