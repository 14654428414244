import React from "react";
import { Calendar } from "@mantine/dates";
import { Spoiler } from "@mantine/core";
import "dayjs/locale/fr";

interface StreakCalendarProps {
  results: { officialDate: string }[];
}

function getUTCDateString(date: Date) {
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, "0");
  const day = String(date.getUTCDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}

const StreakCalendar: React.FC<StreakCalendarProps> = ({ results }) => {
  const streakDates = new Set(results.map((r) => r.officialDate.split("T")[0]));
  const today = getUTCDateString(new Date());

  return (
    <Spoiler
      maxHeight={0}
      showLabel="Voir le détail du streak"
      hideLabel="Cacher le détail"
    >
      <Calendar
        static
        locale="fr"
        firstDayOfWeek={1}
        weekendDays={[0]}
        renderDay={(date) => {
          const dateString = getUTCDateString(date);
          const isStreakDay = streakDates.has(dateString);
          const isToday = dateString === today;

          return (
            <div
              style={{
                position: "relative",
                height: "100%",
                width: "100%",
                backgroundColor: isStreakDay ? "#4CAF50" : "transparent",
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: isStreakDay ? "white" : "inherit",
                border: isToday ? "2px solid #1a73e8" : "none",
                boxSizing: "border-box",
              }}
            >
              {date.getUTCDate()}
            </div>
          );
        }}
      />
    </Spoiler>
  );
};

export default StreakCalendar;
