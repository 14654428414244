import React, { useEffect, useState } from "react";
import {
  Box,
  Paper,
  Text,
  Group,
  Button,
  Accordion,
  Modal,
} from "@mantine/core";
import { UserQuest, DailyQuestValidation } from "../dto/user";
import {
  format,
  startOfDay,
  subDays,
  isEqual,
  isAfter,
  // isBefore,
  isSameDay,
} from "date-fns";
import { confettiEffects } from "../utils/confettiEffects";
import QuestContributionGraph from "./QuestContributionGraph";

interface QuestListProps {
  userQuests: UserQuest[];
  onValidateQuest: (userQuestId: number, date: Date) => void;
  onInvalidateQuest: (userQuestId: number, date: Date) => void;
  onFinishQuest: (userQuestId: number) => void;
  onCancelQuest: (userQuestId: number) => void;
  disabled?: boolean;
}

const isDateValidated = (validations: DailyQuestValidation[], date: Date) => {
  const targetDate = startOfDay(date);
  return validations.some(
    (validation) =>
      validation.date && isEqual(startOfDay(validation.date), targetDate)
  );
};

const QuestItem: React.FC<{
  userQuest: UserQuest;
  onValidateQuest: (userQuestId: number, date: Date) => void;
  onInvalidateQuest: (userQuestId: number, date: Date) => void;
  onFinishQuest: (userQuestId: number) => void;
  onCancelQuest: (userQuestId: number) => void;
  isActive: boolean;
  disabled?: boolean;
}> = ({
  userQuest,
  onValidateQuest,
  onInvalidateQuest,
  onFinishQuest,
  onCancelQuest,
  isActive,
  disabled,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dateToValidate, setDateToValidate] = useState<Date | null>(null);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkMobile();
    window.addEventListener("resize", checkMobile);

    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  const today = startOfDay(new Date());
  const yesterday = subDays(today, 1);
  const startDate = userQuest.startDate
    ? startOfDay(new Date(userQuest.startDate))
    : today;

  const hasStarted = !isAfter(startDate, today);
  const isValidatedToday = isDateValidated(
    userQuest.DailyQuestValidation || [],
    today
  );
  const isValidatedYesterday = isDateValidated(
    userQuest.DailyQuestValidation || [],
    yesterday
  );

  const isStartedToday = () => isSameDay(startDate, today);

  const handleConfirmValidation = () => {
    if (dateToValidate) {
      onValidateQuest(userQuest.id!, dateToValidate);
      setIsModalOpen(false);
      setDateToValidate(null);

      const randomEffect =
        confettiEffects[Math.floor(Math.random() * confettiEffects.length)];
      randomEffect();
    }
  };

  const hasBeenValidated =
    userQuest.DailyQuestValidation && userQuest.DailyQuestValidation.length > 0;

  const handleValidateOrInvalidateClick = (date: Date) => {
    setDateToValidate(date);
    setIsModalOpen(true);
  };

  return (
    <Paper p="md" mb={16} withBorder>
      <Group justify="space-between" align="flex-start">
        <Box>
          <Text fw={500}>
            {userQuest.customTitle || userQuest.quest?.title || "Titre inconnu"}
          </Text>
          <Text size="sm" mt={2}>
            {userQuest.customDescription ||
              (userQuest.quest?.description
                ? `${userQuest.quest?.description} ${
                    userQuest.customPrecision ?? ""
                  }`
                : "Aucune description disponible")}
          </Text>
          <Text size="sm" c="dimmed">
            Débutée le :{" "}
            {userQuest.startDate
              ? format(userQuest.startDate, "dd/MM/yyyy")
              : "Date inconnue"}
          </Text>
          {!isActive && userQuest.endDate && (
            <Text size="sm" c="dimmed">
              Terminée le : {format(userQuest.endDate, "dd/MM/yyyy")}
            </Text>
          )}
        </Box>
        {isActive && !disabled && (
          <Button
            size="xs"
            color={hasBeenValidated ? "red" : "gray"}
            onClick={() =>
              hasBeenValidated
                ? onFinishQuest(userQuest.id!)
                : onCancelQuest(userQuest.id!)
            }
            variant="light"
          >
            {hasBeenValidated ? "Terminer la quête" : "Annuler la quête"}
          </Button>
        )}
      </Group>
      {isActive && !disabled && (
        <Group mt={8}>
          <Button
            size="xs"
            onClick={() => handleValidateOrInvalidateClick(new Date())}
            disabled={!hasStarted}
            color={isValidatedToday ? "gray" : undefined}
            variant={isValidatedToday ? "light" : undefined}
          >
            {isValidatedToday ? "Invalider aujourd'hui" : "Valider aujourd'hui"}
          </Button>
          <Button
            size="xs"
            onClick={() => handleValidateOrInvalidateClick(yesterday)}
            disabled={!hasStarted || isStartedToday()}
            color={isValidatedYesterday ? "gray" : undefined}
            variant={isValidatedYesterday ? "light" : undefined}
          >
            {isValidatedYesterday ? "Invalider hier" : "Valider hier"}
          </Button>
        </Group>
      )}
      <Text mt={8} fw={500}>
        Progression :
      </Text>
      {userQuest.startDate ? (
        <QuestContributionGraph
          startDate={new Date(userQuest.startDate)}
          endDate={userQuest.endDate ? new Date(userQuest.endDate) : null}
          validations={userQuest.DailyQuestValidation || []}
          isMobile={isMobile}
        />
      ) : (
        <Text size="sm" c="dimmed">
          Date de début non définie.
        </Text>
      )}

      <Modal
        opened={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={
          dateToValidate &&
          isDateValidated(userQuest.DailyQuestValidation || [], dateToValidate)
            ? "Confirmer l'invalidation"
            : "Confirmer la validation"
        }
      >
        <Text>
          Êtes-vous sûr de vouloir{" "}
          {dateToValidate &&
          isDateValidated(userQuest.DailyQuestValidation || [], dateToValidate)
            ? "invalider"
            : "valider"}{" "}
          cette quête pour le{" "}
          {dateToValidate
            ? format(dateToValidate, "dd/MM/yyyy")
            : "jour sélectionné"}
          ?
        </Text>
        <Group mt="md">
          <Button
            onClick={() => {
              if (dateToValidate) {
                if (
                  isDateValidated(
                    userQuest.DailyQuestValidation || [],
                    dateToValidate
                  )
                ) {
                  onInvalidateQuest(userQuest.id!, dateToValidate);
                } else {
                  handleConfirmValidation();
                }
              }
              setIsModalOpen(false);
              setDateToValidate(null);
            }}
          >
            Confirmer
          </Button>
          <Button onClick={() => setIsModalOpen(false)} variant="outline">
            Annuler
          </Button>
        </Group>
      </Modal>
    </Paper>
  );
};

const QuestList: React.FC<QuestListProps> = ({
  userQuests,
  onValidateQuest,
  onInvalidateQuest,
  onFinishQuest,
  onCancelQuest,
  disabled,
}) => {
  const [showCompleted, setShowCompleted] = useState(false);
  const activeQuests = userQuests.filter((quest) => !quest.endDate);
  const completedQuests = userQuests.filter((quest) => quest.endDate);

  return (
    <Box mt={16}>
      <Text fw={700} mb={16}>
        Quêtes actives ({activeQuests.length})
      </Text>
      {activeQuests.map((userQuest: UserQuest) => (
        <QuestItem
          key={userQuest.id}
          userQuest={userQuest}
          onValidateQuest={onValidateQuest}
          onInvalidateQuest={onInvalidateQuest}
          onFinishQuest={onFinishQuest}
          onCancelQuest={onCancelQuest}
          isActive={true}
          disabled={disabled}
        />
      ))}

      <Accordion mt={32}>
        <Accordion.Item value="completed">
          <Accordion.Control onClick={() => setShowCompleted(!showCompleted)}>
            <Text fw={700}>Quêtes terminées ({completedQuests.length})</Text>
          </Accordion.Control>
          <Accordion.Panel>
            {showCompleted &&
              completedQuests.map((userQuest: UserQuest) => (
                <QuestItem
                  key={userQuest.id}
                  userQuest={userQuest}
                  onValidateQuest={onValidateQuest}
                  onInvalidateQuest={onInvalidateQuest}
                  onFinishQuest={onFinishQuest}
                  isActive={false}
                  onCancelQuest={onCancelQuest}
                />
              ))}
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    </Box>
  );
};

export default QuestList;
