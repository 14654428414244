import React, { useState } from "react";
import {
  Box,
  Title,
  Table,
  Group,
  Button,
  Modal,
  TextInput,
  Select,
  Text,
} from "@mantine/core";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { MiniGame, Skill, MiniGameCategory } from "../dto/user";
import { useApi } from "../useApi";
import { getMiniGames, createEditMiniGame, getSkills } from "../apiRoutes";
import { useForm } from "@mantine/form";
import RichTextEditorField from "../Components/RichTextEditorField";

export const getMiniGameCategory = (category: any): string => {
  switch (category) {
    case "SINGLE_QUESTION":
      return "Question unique";
    case "CHAT":
      return "Conversation";
    case "QUIZ":
      return "Quiz";
    default:
      return "Catégorie inconnue";
  }
};

const AdminMiniGames = () => {
  const api = useApi();
  const queryClient = useQueryClient();
  const [modalOpened, setModalOpened] = useState(false);
  const [editingMiniGame, setEditingMiniGame] = useState<MiniGame | null>(null);

  const {
    data: miniGames,
    isLoading: miniGamesLoading,
    error: miniGamesError,
  } = useQuery<MiniGame[], Error>({
    queryKey: ["miniGames"],
    queryFn: () => getMiniGames(api),
  });

  const {
    data: skills,
    isLoading: skillsLoading,
    error: skillsError,
  } = useQuery<Skill[], Error>({
    queryKey: ["skills"],
    queryFn: () => getSkills(api),
  });

  const createEditMiniGameMutation = useMutation({
    mutationFn: (miniGame: Omit<MiniGame, "id"> | MiniGame) =>
      createEditMiniGame(api, miniGame),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["miniGames"] });
      setModalOpened(false);
      form.reset();
      setEditingMiniGame(null);
    },
  });

  const form = useForm({
    initialValues: {
      id: 0,
      title: "",
      description: "",
      prompt: "",
      skillId: "",
      timeLimit: 300,
      category: "SINGLE_QUESTION",
    },
    validate: {
      title: (value) => (value.length > 0 ? null : "Title is required"),
      skillId: (value) => (value ? null : "Skill is required"),
      timeLimit: (value) => (value > -1 ? null : "Time limit must be positive"),
      category: (value) => (value ? null : "Category is required"),
    },
  });

  const handleOpenModal = (miniGame?: MiniGame) => {
    if (miniGame) {
      setEditingMiniGame(miniGame);
      console.log("miniGame", miniGame);
      form.setValues({
        id: miniGame.id,
        title: miniGame.title,
        description: miniGame.description,
        prompt: miniGame.prompt || "",
        skillId: miniGame.skillId?.toString() || "",
        timeLimit: miniGame.timeLimit || 0,
        category: miniGame.category || "SINGLE_QUESTION",
      });
    } else {
      setEditingMiniGame(null);
      form.reset();
    }
    setModalOpened(true);
  };

  const handleSubmit = (values: typeof form.values) => {
    const miniGameData: Partial<MiniGame> = {
      title: values.title,
      description: values.description,
      prompt: values.prompt || "",
      skillId: parseInt(values.skillId, 10),
      timeLimit: +values.timeLimit,
      category: values.category as MiniGameCategory,
    };
    if (editingMiniGame) {
      miniGameData.id = editingMiniGame.id;
    }
    createEditMiniGameMutation.mutate(miniGameData as MiniGame);
  };

  if (miniGamesLoading || skillsLoading) return <div>Loading...</div>;
  if (miniGamesError || skillsError)
    return <div>Error: {(miniGamesError || skillsError)?.message}</div>;

  return (
    <Box p={16}>
      <Group mb={16}>
        <Title>Admin Mini-Games</Title>
        <Button onClick={() => handleOpenModal()}>Create New Mini-Game</Button>
      </Group>
      <Table>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>ID</Table.Th>
            <Table.Th>Title</Table.Th>
            <Table.Th>Skill</Table.Th>
            <Table.Th>Time Limit</Table.Th>
            <Table.Th>Category</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {miniGames &&
            miniGames.map((miniGame: MiniGame) => (
              <Table.Tr
                key={miniGame.id}
                onClick={() => handleOpenModal(miniGame)}
                style={{ cursor: "pointer" }}
              >
                <Table.Td>{miniGame.id}</Table.Td>
                <Table.Td>{miniGame.title}</Table.Td>
                <Table.Td>
                  {skills?.find((s) => s.id === miniGame.skillId)?.name}
                </Table.Td>
                <Table.Td>{miniGame.timeLimit} seconds</Table.Td>
                <Table.Td>{getMiniGameCategory(miniGame.category)}</Table.Td>
              </Table.Tr>
            ))}
        </Table.Tbody>
      </Table>

      <Modal
        opened={modalOpened}
        onClose={() => {
          setModalOpened(false);
          setEditingMiniGame(null);
          form.reset();
        }}
        title={editingMiniGame ? "Edit Mini-Game" : "Create New Mini-Game"}
        size="lg"
      >
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <TextInput
            label="Title"
            placeholder="Enter mini-game title"
            {...form.getInputProps("title")}
            required
          />
          <Text mb={-14} mt="sm" size="sm">
            Description
          </Text>
          <RichTextEditorField
            content={form.values.description}
            onChange={(content) => form.setFieldValue("description", content)}
          />
          <Text mb={-14} mt="sm" size="sm">
            Prompt
          </Text>
          <RichTextEditorField
            content={form.values.prompt}
            onChange={(content) => form.setFieldValue("prompt", content)}
          />
          <Select
            label="Skill"
            placeholder="Select a skill"
            data={
              skills?.map((skill) => ({
                value: skill.id?.toString() || "",
                label: `${skill.name} - ${skill.area?.value}`,
              })) || []
            }
            mt="md"
            {...form.getInputProps("skillId")}
            required
            searchable
          />
          <TextInput
            label="Time Limit (seconds)"
            type="number"
            placeholder="Enter time limit in seconds"
            mt="md"
            {...form.getInputProps("timeLimit")}
            required
          />
          <Select
            label="Category"
            placeholder="Select a category"
            data={[
              {
                value: "SINGLE_QUESTION",
                label: getMiniGameCategory("SINGLE_QUESTION"),
              },
              {
                value: "CHAT",
                label: getMiniGameCategory("CHAT"),
              },
              {
                value: "QUIZ",
                label: getMiniGameCategory("QUIZ"),
              },
            ]}
            mt="md"
            {...form.getInputProps("category")}
            required
          />
          <Group justify="flex-end" mt="md">
            <Button
              type="submit"
              loading={createEditMiniGameMutation.isPending}
            >
              {editingMiniGame ? "Update Mini-Game" : "Create Mini-Game"}
            </Button>
          </Group>
        </form>
      </Modal>
    </Box>
  );
};

export default AdminMiniGames;
