import { Alert, Badge, Box, Button, Loader, Text, Title } from "@mantine/core";
import React, { useContext } from "react";
import { UserContext } from "../context/UserContext";
import { useStreak } from "../hooks/useStreak";
import { IconAlertCircle, IconFlame } from "@tabler/icons-react";
import StreakCalendar from "../Components/StreakCalendar";
import { useNavigate } from "react-router-dom";
// import WelcomeMessage from "../Components/WelcomeMessage";

const Home: React.FC = () => {
  const { user } = useContext(UserContext);
  const { currentStreak, playedToday, isLoading, error, results } = useStreak();
  const navigate = useNavigate();

  return (
    <Box p={16}>
      <Title>
        Bienvenue {user?.pseudo} {JSON.stringify(playedToday)}
      </Title>
      {/* <WelcomeMessage /> */}

      {isLoading ? (
        <Loader />
      ) : error ? (
        <Text c="red">Erreur lors du chargement des résultats</Text>
      ) : (
        <>
          <Box my={20}>
            <Badge size="xl" leftSection={<IconFlame size={18} />}>
              {currentStreak} jours de streak
            </Badge>
          </Box>

          {!playedToday && (
            <Alert
              icon={<IconAlertCircle size={16} />}
              title="Rappel"
              color="blue"
              mt={16}
              mb={16}
            >
              <div>
                N'oubliez pas de faire votre mini-jeu du jour pour maintenir
                votre streak !
              </div>
              <Button mt={15} onClick={() => navigate("/mini-games")}>
                Voir les Mini-Jeux
              </Button>
            </Alert>
          )}

          {results && <StreakCalendar results={results} />}
        </>
      )}
      <Text>
        Bientot ici un chatbot pour choisir ses compétences à développer
      </Text>
    </Box>
  );
};

export default Home;
