import React from "react";
import {
  Box,
  SimpleGrid,
  Text,
  Title,
  UnstyledButton,
  useMantineTheme,
  useMantineColorScheme,
  Paper,
  Group,
  Stack,
} from "@mantine/core";
import {
  IconDeviceGamepad,
  IconMessageCircle2,
  IconTarget,
  IconUserCircle,
  //   IconBuildingBank,
  //   IconCashBanknote,
  //   IconCoin,
  //   IconReceipt,
  //   IconReceiptRefund,
  //   IconReceiptTax,
  //   IconRepeat,
  //   IconReport,
} from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";

const mockdata = [
  {
    title: "Feedbacks",
    icon: IconMessageCircle2,
    color: "violet",
    link: "/admin-feedbacks",
  },
  { title: "Quests", icon: IconTarget, color: "indigo", link: "/admin-quests" },
  {
    title: "Historique Jeux",
    icon: IconDeviceGamepad,
    color: "blue",
    link: "/games-history",
  },
  {
    title: "Interlocuteurs",
    icon: IconUserCircle,
    color: "green",
    link: "/interlocutors",
  },
  //   { title: "Receipts", icon: IconReceipt, color: "teal" },
  //   { title: "Taxes", icon: IconReceiptTax, color: "cyan" },
  //   { title: "Reports", icon: IconReport, color: "pink" },
  //   { title: "Payments", icon: IconCoin, color: "red" },
  //   { title: "Cashback", icon: IconCashBanknote, color: "orange" },
];

const Admin = () => {
  const theme = useMantineTheme();
  const { colorScheme } = useMantineColorScheme();
  const navigate = useNavigate();

  const items = mockdata.map((item) => (
    <UnstyledButton
      key={item.title}
      style={{
        display: "block",
        width: "100%",
        padding: theme.spacing.xs,
        borderRadius: theme.radius.sm,
        color: colorScheme === "dark" ? theme.colors.dark[0] : theme.black,
        "&:hover": {
          backgroundColor:
            colorScheme === "dark"
              ? theme.colors.dark[6]
              : theme.colors.gray[0],
        },
      }}
      onClick={() => {
        if (item?.link) navigate(item.link);
      }}
    >
      <Paper shadow="sm" p="md" radius="md" h="100%" withBorder>
        <Group justify="center" h="100%" dir="column">
          <Stack>
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <item.icon
                color={theme.colors[item.color][6]}
                size={32}
                stroke={1.5}
              />
            </Box>
            <Text size="sm" ta="center">
              {item.title}
            </Text>
          </Stack>
        </Group>
      </Paper>
    </UnstyledButton>
  ));

  return (
    <Box
      p={{ base: "md", sm: "xl" }}
      bg={colorScheme === "dark" ? theme.colors.dark[8] : theme.colors.gray[0]}
      mih="100vh"
    >
      <Title order={2} c={colorScheme === "dark" ? "white" : "dark"} mb="xl">
        Admin Dashboard
      </Title>
      <SimpleGrid
        cols={{ base: 2, sm: 3, md: 4, lg: 5 }}
        spacing={{ base: "sm", sm: "md", md: "lg" }}
        verticalSpacing={{ base: "sm", sm: "md", md: "lg" }}
      >
        {items}
      </SimpleGrid>
    </Box>
  );
};

export default Admin;
