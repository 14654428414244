import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import {
  Box,
  Title,
  Text,
  Group,
  LoadingOverlay,
  Badge,
  Avatar,
} from "@mantine/core";
import { useApi } from "../useApi";
import { getUserByPseudo, getUserQuestsByPseudo } from "../apiRoutes";
import QuestList from "../Components/QuestList";
import ContributionGraph from "../Components/ContributionGraph";
import { UserQuest } from "../dto/user";
import ProfileButtons from "../Components/ProfileButtons";

const User: React.FC = () => {
  const { pseudo } = useParams<{ pseudo: string }>();
  const api = useApi();

  const { data: user, isLoading: isLoadingUser } = useQuery({
    queryKey: ["user", pseudo],
    queryFn: () => getUserByPseudo(api, pseudo || ""),
    enabled: !!pseudo,
    retry: false,
    staleTime: 10000,
  });

  const { data: userQuests, isLoading: isLoadingQuests } = useQuery({
    queryKey: ["userQuests", pseudo],
    queryFn: () => getUserQuestsByPseudo(api, pseudo || ""),
    enabled: !!pseudo,
    retry: false,
    staleTime: 10000,
  });

  if (isLoadingUser || isLoadingQuests) {
    return <LoadingOverlay visible={true} />;
  }
  if (!user) {
    return <Text>User not found</Text>;
  }

  const totalQuestPoints =
    (userQuests as UserQuest[] | undefined)?.reduce((total, quest) => {
      return total + (quest.DailyQuestValidation?.length || 0);
    }, 0) || 0;

  return (
    <Box p={16}>
      <Group>
        <Avatar
          size="xl"
          src={user?.profilePictureUrl}
          radius="100px"
          color="theme"
        >
          {user?.pseudo ? user.pseudo.slice(0, 1).toUpperCase() : null}
        </Avatar>
        <Title>Profil de {user.pseudo}</Title>
      </Group>

      <Box mt={32}>
        <Group align="center">
          <Title order={3}>Petits pas</Title>
          <Text>Total points:</Text>
          <Badge variant="filled" color="theme">
            {totalQuestPoints} points
          </Badge>
          <ProfileButtons
            username={user.pseudo}
            showProfileButton={false}
            isPublicProfile={user.isPublicProfile}
          />
        </Group>
        <Text c="dimmed" size="xs">
          Actions quotidiennes en cours
        </Text>

        {userQuests.length > 0 ? (
          <>
            <ContributionGraph userQuests={userQuests} />
            <QuestList
              userQuests={userQuests || []}
              onValidateQuest={() => {}}
              onInvalidateQuest={() => {}}
              onFinishQuest={() => {}}
              onCancelQuest={() => {}}
              disabled
            />
          </>
        ) : (
          <Text mt={16}>Aucune quête en cours pour le moment.</Text>
        )}
      </Box>
    </Box>
  );
};

export default User;
