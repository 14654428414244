import React from "react";
import {
  Box,
  Title,
  SimpleGrid,
  Card,
  Avatar,
  Text,
  Switch,
  LoadingOverlay,
} from "@mantine/core";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { showNotification } from "@mantine/notifications";
import { useApi } from "../useApi";

interface Interlocutor {
  id: number;
  name: string;
  avatarUrl: string | null;
  isActive: boolean;
}

const InterlocutorManager: React.FC = () => {
  const api = useApi();
  const queryClient = useQueryClient();

  const { data: interlocutors, isLoading: isLoadingInterlocutors } = useQuery<
    Interlocutor[]
  >({
    queryKey: ["userInterlocutors"],
    queryFn: () => api.get("/interlocutors/user").then((res) => res.data),
  });

  const toggleInterlocutorMutation = useMutation({
    mutationFn: ({ id, isActive }: { id: number; isActive: boolean }) =>
      api.put(`/interlocutors/toggle/${id}`, { isActive }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["userInterlocutors"] });
      showNotification({
        title: "Succès",
        message: "Le statut de l'interlocuteur a été mis à jour",
        color: "green",
      });
    },
    onError: () => {
      showNotification({
        title: "Erreur",
        message:
          "Une erreur est survenue lors de la mise à jour de l'interlocuteur",
        color: "red",
      });
    },
  });

  const handleToggleInterlocutor = (id: number, isActive: boolean) => {
    toggleInterlocutorMutation.mutate({ id, isActive });
  };

  return (
    <Box>
      <Title order={2} mb="md" mt="md">
        Gestion des Interlocuteurs
      </Title>
      <Box pos="relative">
        <LoadingOverlay visible={isLoadingInterlocutors} />
        <SimpleGrid
          cols={{ base: 2, xs: 2, sm: 3, md: 4, lg: 5 }}
          spacing={{ base: "sm", sm: "md", md: "lg" }}
          verticalSpacing={{ base: "md", sm: "xl" }}
        >
          {interlocutors?.map((interlocutor) => (
            <Card
              key={interlocutor.id}
              shadow="sm"
              padding="sm"
              radius="md"
              withBorder
            >
              <Card.Section>
                <Avatar
                  src={interlocutor.avatarUrl}
                  size="lg"
                  radius="md"
                  mx="auto"
                  mt="xs"
                />
              </Card.Section>
              <Text size="sm" fw={500} ta="center" mt="xs" lineClamp={2}>
                {interlocutor.name}
              </Text>
              <Switch
                size="sm"
                checked={interlocutor.isActive}
                onChange={(event) =>
                  handleToggleInterlocutor(
                    interlocutor.id,
                    event.currentTarget.checked
                  )
                }
                label={interlocutor.isActive ? "Activé" : "Désactivé"}
                labelPosition="left"
                mt="xs"
                styles={{
                  label: { fontSize: "0.8rem" },
                  root: { width: "100%", justifyContent: "space-between" },
                }}
              />
            </Card>
          ))}
        </SimpleGrid>
      </Box>
    </Box>
  );
};

export default InterlocutorManager;
