import React from "react";
import {
  Box,
  Group,
  Loader,
  Text,
  useMantineTheme,
  useMantineColorScheme,
} from "@mantine/core";
import { IconCheck, IconPencil, IconX } from "@tabler/icons-react";

const SaveStatus = ({ saveStatus }: { saveStatus: string | null }) => {
  const theme = useMantineTheme();
  const { colorScheme } = useMantineColorScheme();
  const isDark = colorScheme === "dark";

  if (!saveStatus) return null;

  const getBackgroundColor = () => {
    return isDark
      ? `rgba(${theme.colors.dark[7]}, 0.8)`
      : "rgba(255, 255, 255, 0.8)";
  };

  const getTextColor = (color: string) => {
    return theme.colors[color][isDark ? 4 : 6];
  };

  return (
    <Box
      style={{
        position: "fixed",
        bottom: 16,
        right: 16,
        padding: "8px 16px",
        borderRadius: theme.radius.md,
        backdropFilter: "blur(10px)",
        backgroundColor: getBackgroundColor(),
        boxShadow: theme.shadows.sm,
      }}
    >
      {saveStatus === "saving" && (
        <Group>
          <Loader size="xs" color={isDark ? "gray" : "dark"} />
          <Text size="sm" c={isDark ? "dimmed" : "dark"}>
            Saving...
          </Text>
        </Group>
      )}
      {saveStatus === "saved" && (
        <Group>
          <IconCheck color={getTextColor("green")} size={18} />
          <Text size="sm" c={getTextColor("green")}>
            Saved
          </Text>
        </Group>
      )}
      {saveStatus === "error" && (
        <Group>
          <IconX color={getTextColor("red")} size={18} />
          <Text size="sm" c={getTextColor("red")}>
            Error saving
          </Text>
        </Group>
      )}
      {saveStatus === "unsaved" && (
        <Group>
          <IconPencil color={getTextColor("gray")} size={18} />
          <Text size="sm" c={getTextColor("gray")}>
            Unsaved changes...
          </Text>
        </Group>
      )}
    </Box>
  );
};

export default SaveStatus;
