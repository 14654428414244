import {
  Box,
  Title,
  Radio,
  Group,
  Button,
  Avatar,
  TextInput,
  Switch,
  PasswordInput,
} from "@mantine/core";
import React, { useState, useContext, useEffect } from "react";
import { UserContext } from "../context/UserContext";
import { useUpdateUser } from "../hooks/useUpdateUser";
import { User } from "../dto/user";
import { useApi } from "../useApi";
import { showNotification } from "@mantine/notifications";
import { useNavigate } from "react-router-dom";
import InterlocutorManager from "../Components/InterlocutorManager";

const avatarOptions = Array.from(
  { length: 10 },
  (_, i) =>
    `https://raw.githubusercontent.com/mantinedev/mantine/master/.demo/avatars/avatar-${
      i + 1
    }.png`
);

const Settings = () => {
  const { user, setUser } = useContext(UserContext);
  const [pseudo, setPseudo] = useState("");
  const [profilePictureUrl, setProfilePictureUrl] = useState("");
  const [isPublicProfile, setIsPublicProfile] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [error, setError] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const api = useApi();
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      setPseudo(user.pseudo || "");
      setProfilePictureUrl(user.profilePictureUrl || "");
      setIsPublicProfile(user.isPublicProfile || false);
      setIsDarkMode(user.defaultColorScheme === "dark" || false);
    }
  }, [user]);

  const mutation = useUpdateUser({
    onSuccess: (data: User) => {
      setUser(data);
      setError("");
      setIsSuccess(true);
    },
    onError: () => {
      setError("Ce pseudo est déjà utilisé");
    },
  });

  useEffect(() => {
    if (isSuccess) {
      const timer = setTimeout(() => {
        setIsSuccess(false);
      }, 4000);
      return () => clearTimeout(timer);
    }
  }, [isSuccess]);

  const validatePseudo = (val: string) => {
    const pseudoRegex = /^[a-zA-Z0-9]{3,}$/;
    if (!pseudoRegex.test(val)) {
      return "Le pseudo doit contenir au moins 3 caractères, sans espaces, et uniquement des lettres et des chiffres";
    }
    return null;
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const pseudoError = validatePseudo(pseudo);
    if (pseudoError) {
      setError(pseudoError);
      return;
    }
    if (user && user.id !== undefined) {
      mutation.mutate({
        id: user.id,
        pseudo,
        profilePictureUrl,
        isPublicProfile,
        defaultColorScheme: isDarkMode ? "dark" : "light",
      });
    }
  };

  const handlePasswordChange = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (newPassword !== confirmNewPassword) {
      showNotification({
        title: "Erreur",
        message: "Les nouveaux mots de passe ne correspondent pas",
        color: "red",
      });
      return;
    }
    try {
      await api.post("/auth/change-password", {
        currentPassword,
        newPassword,
      });
      showNotification({
        title: "Succès",
        message: "Votre mot de passe a été changé avec succès",
        color: "green",
      });
      setCurrentPassword("");
      setNewPassword("");
      setConfirmNewPassword("");
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        showNotification({
          title: "Erreur",
          message: "Le mot de passe actuel est incorrect",
          color: "red",
        });
      } else {
        showNotification({
          title: "Erreur",
          message: "Une erreur est survenue lors du changement de mot de passe",
          color: "red",
        });
      }
    }
  };

  return (
    <Box p={16}>
      <Title mb="md">Paramètres</Title>
      <Button mb="sm" onClick={() => navigate("/feedback")}>
        Feedback - Aide - Rapporter un bug
      </Button>
      <form onSubmit={handleSubmit}>
        <TextInput
          label="Pseudo"
          value={pseudo}
          onChange={(e) => setPseudo(e.target.value)}
          required
          error={error}
        />
        <Radio.Group
          label="Avatar"
          value={profilePictureUrl}
          onChange={setProfilePictureUrl}
          description="Choisissez un avatar parmi les options suivantes :"
          required
        >
          <Group mt="xs">
            <Radio
              value=""
              label={
                <Avatar radius="xl" size="lg" color="theme">
                  {user?.pseudo ? user.pseudo.slice(0, 1).toUpperCase() : null}
                </Avatar>
              }
            />
            {avatarOptions.map((url) => (
              <Radio
                key={url}
                value={url}
                label={<Avatar src={url} radius="xl" size="lg" />}
              />
            ))}
          </Group>
        </Radio.Group>
        <Switch
          label="Profil public"
          description="Apparaître dans l'annuaire avec votre graphique de quêtes publiques"
          checked={isPublicProfile}
          onChange={(event) => setIsPublicProfile(event.currentTarget.checked)}
          mt={20}
        />
        <Switch
          label="Mode sombre"
          description="Activer le mode sombre pour l'interface"
          checked={isDarkMode}
          onChange={(event) => setIsDarkMode(event.currentTarget.checked)}
          mt={20}
        />
        <Button type="submit" mt={15} color={isSuccess ? "green" : "theme"}>
          Enregistrer
        </Button>
      </form>

      <InterlocutorManager />

      {(user?.hashedPassword?.length ?? 0) > 0 && (
        <>
          <Title order={2} mt={30}>
            Changer le mot de passe
          </Title>
          <form onSubmit={handlePasswordChange}>
            <PasswordInput
              label="Mot de passe actuel"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              required
              mt={10}
            />
            <PasswordInput
              label="Nouveau mot de passe"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
              mt={10}
            />
            <PasswordInput
              label="Confirmer le nouveau mot de passe"
              value={confirmNewPassword}
              onChange={(e) => setConfirmNewPassword(e.target.value)}
              required
              mt={10}
            />
            <Button type="submit" mt={15}>
              Changer le mot de passe
            </Button>
          </form>
        </>
      )}

      {window.location.href.includes("newUser=true") && (
        <Button mt={15} onClick={() => navigate("/")}>
          Aller à la page d'accueil
        </Button>
      )}
    </Box>
  );
};

export default Settings;
