import { useQuery } from "@tanstack/react-query";
import { useApi } from "../useApi";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

interface MiniGameResult {
  id: number;
  officialDate: string;
  // Autres propriétés si nécessaire
}

export const useStreak = () => {
  const api = useApi();

  const {
    data: results,
    isLoading,
    error,
  } = useQuery<MiniGameResult[]>({
    queryKey: ["miniGameResults"],
    queryFn: () => api.get("/mini-games/results").then((res) => res.data),
  });

  const calculateStreak = (results: MiniGameResult[]) => {
    if (!results || results.length === 0)
      return { currentStreak: 0, playedToday: false };

    // Convertir les dates en objets Day.js en UTC, début de journée
    const dates = results.map((result) =>
      dayjs.utc(result.officialDate).startOf("day")
    );

    // Supprimer les doublons au cas où il y aurait plusieurs résultats le même jour
    const uniqueDates = Array.from(
      new Set(dates.map((date) => date.valueOf()))
    ).map((timestamp) => dayjs.utc(timestamp));

    // Trier les dates par ordre décroissant
    const sortedDates = uniqueDates.sort((a, b) => b.valueOf() - a.valueOf());

    const today = dayjs.utc().startOf("day");
    let currentStreak = 0;
    let streakValid = false;
    let playedToday = false;

    for (let i = 0; i < sortedDates.length; i++) {
      const date = sortedDates[i];

      if (i === 0) {
        const daysDiff = today.diff(date, "day");

        if (daysDiff === 0) {
          // L'utilisateur a joué aujourd'hui
          playedToday = true;
          streakValid = true;
          currentStreak = 1;
        } else if (daysDiff === 1) {
          // L'utilisateur a joué hier
          playedToday = false;
          streakValid = true;
          currentStreak = 1;
        } else {
          // Le streak est brisé
          break;
        }
      } else {
        const prevDate = sortedDates[i - 1];
        const daysDiff = prevDate.diff(date, "day");

        if (daysDiff === 1) {
          currentStreak++;
        } else {
          // Le streak est brisé
          break;
        }
      }
    }

    return {
      currentStreak: streakValid ? currentStreak : 0,
      playedToday,
    };
  };

  const streakInfo = results
    ? calculateStreak(results)
    : { currentStreak: 0, playedToday: false };

  return {
    ...streakInfo,
    results,
    isLoading,
    error,
  };
};
