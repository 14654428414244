import { Box } from "@mantine/core";
import MiniGameResultsAccordion from "../Components/MiniGameResultsAccordion";

const GamesHistory = () => {
  return (
    <Box p={16}>
      <MiniGameResultsAccordion skillId={0} isAdmin />
    </Box>
  );
};
export default GamesHistory;
