import { Box, Title } from "@mantine/core";
import Achievements from "./Achievements";
import { useEvolutionData } from "../hooks/useEvolutionData";
import ChartEvolution from "../Components/ChartEvolution";
import { useSkills } from "../context/SkillsContext";
import PersonalInfo from "../Components/PersonalInfo";

const Statistics = () => {
  const { skills } = useSkills();
  const evolutionData = useEvolutionData(skills);

  return (
    <Box p={16}>
      <Title>Statistiques</Title>
      <Achievements />
      <PersonalInfo />

      {evolutionData.length ? (
        <>
          <Title order={3} mt="md" mb="sm">
            Progression des compétences
          </Title>
          <ChartEvolution chartData={evolutionData} dataKey="total" />
        </>
      ) : null}
    </Box>
  );
};
export default Statistics;
